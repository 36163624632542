(function( root, $, undefined ) {
	"use strict";

	$(function () {

		$(document).ready(function($) {

			MicroModal.init();

			var store = localStorage.getItem('currency');

			if (store) {
				$('body').attr('data-currency', store);
			}
			else {
				$('body').attr('data-currency', 'cur__US');
			}

			if($('body').data('currency') == 'cur__US') {
				$('.modal .modal__content .left button').addClass('active');
			}
			else if ($('body').data('currency') == 'cur__CAD') {
				$('.modal .modal__content .right button').addClass('active');
			}

			$('.modal__content button').on('click', function(e) {
				if ($('.modal__content button').hasClass('active')) {
					$('.modal__content button.active').removeClass('active');
					$(this).addClass('active');
				}
				else {
					$(this).toggleClass('active');
				}
			});

			$('.modal__footer button').on('mousedown touchstart', function(e) {
				if ($('.modal .modal__content .left button').hasClass('active')) {
					$('body').attr('data-currency', 'cur__US');
					localStorage.setItem('currency', 'cur__US');
				}
				else if ($('.modal .modal__content .right button').hasClass('active')) {
					$('body').attr('data-currency', 'cur__CAD');
					localStorage.setItem('currency', 'cur__CAD');
				}
			});

	  });

	});

} ( this, jQuery ));

(function( root, $, undefined ) {
	"use strict";

	$(function () {

		$(document).ready(function($) {

			$('.accordion').each(function() {
				$(this).find('.accordion-toggle').click(function(){

					var $clickedToggle = $(this);
					$(this).toggleClass('active');
					// Expand or collapse this panel
					$(this).next().slideToggle('slow');

					// Hide the other panels
					$(this).parents('.accordion').find($(".accordion-toggle").not($(this))).removeClass('active');
					$(this).parents('.accordion').find($(".accordion-content").not($(this).next())).slideUp('fast');
					$('html, body').animate({scrollTop: $clickedToggle.parent().offset().top}, 'fast');
				});
			});

		});

	});

} ( this, jQuery ));

(function( _, $ ) {
	'use strict';

	$(window).on('load', function () {
		const goLiveBtn = document.querySelector('.gis-circle');
		const buildBar = document.querySelector('.build-bar');
		const startState = document.querySelector('.start-state');

		if(!!goLiveBtn && !!buildBar) {
			goLiveBtn.style.transition = 'all 1s';
			buildBar.style.bottom = '-1px'; // Style .build-bar to go outside the window so we can trigger IntersectionObserver
			let bottomCssVal = 65;

			// observe changes in .build-bar element so when it's
			// position is stickied to the bottom of the page, we can move the GoLive button
			// so it won't overlap with the .build-bar
			//https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
			const buildBarObserver = new IntersectionObserver(function (entries) {
				entries.forEach(entry => {
					if(entry.intersectionRatio < 1 && entry.boundingClientRect.bottom > window.innerHeight) {
						goLiveBtn.style.bottom = bottomCssVal.toString() + 'px';
					} else {
						goLiveBtn.style.bottom = '0';
					}
				});
			}, {
				threshold: 1
			});
			buildBarObserver.observe(buildBar);

			// observe .start-state inside of .build-bar
			const startStateObserver = new IntersectionObserver(function (entries) {
				entries.forEach(entry => {
					if(entry.intersectionRatio < 1) {
						bottomCssVal += 30;
					} else if(bottomCssVal > 65) {
						bottomCssVal -= 30;
					}
					goLiveBtn.style.bottom = bottomCssVal.toString() + 'px';
				});
			}, {
				root: document.documentElement,
				threshold: 1
			});
			startStateObserver.observe(startState);
		}
	});

} ( this, jQuery ));

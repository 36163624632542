(function( root, $ ) {
	"use strict";

	$(function () {

		$(document).ready(function($) {

			$('.ginput_recaptcha').each(function(i) {
				var thisID = $(this).attr('id');
				$(this).attr('id', thisID+'_'+i);
			});
		});

	});

} ( this, jQuery ));

import slick from 'slick-carousel';

(function( root, $ ) {
  "use strict";

  $(function () {

    $(document).ready(function($) {

      const galleryCategoryClassname = 'gallery--visible';
      const activeGalleryControlClassname = 'categorized-gallery__control--active';
      const activeKeyFeatureControlsClassname = 'key-features__control--active';
      const mobileControlsClassname = 'categorized-gallery__control-wrapper--active';
      const mobileKeyFeaturesControlsClassname = 'key-features__control-wrapper--active';
      const gallerySlider = {
        arrows: true,
        dots: true,
        draggable: false,
        slidesToScroll: 1,
        slidesToShow: 1,
        prevArrow: $('.categorized-gallery__slider .categorized-gallery__arrow__prev'),
        nextArrow: $('.categorized-gallery__slider .categorized-gallery__arrow__next'),
      };
      const modalSlider = {
        arrows: true,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: $('.modal-slider__content .modal-slider__arrow__prev'),
        nextArrow: $('.modal-slider__content .modal-slider__arrow__next'),
      };
      const keyFeatureSlider = {
        arrows: true,
        dots: true,
        draggable: false,
        slidesToShow: 4,
        initialSlide: 0,
        slidesToScroll: 1,
        prevArrow: $('.key-features__slider .key-features__arrows__prev'),
        nextArrow: $('.key-features__slider .key-features__arrows__next'),
        responsive: [{
          breakpoint: 1000,
          settings: {
            slidesToShow: 1,
		  	centerMode: true,
		  	centerPadding: '0px',
          }
        }]
      };

      const $slidePosition = $('.modal-slider-info__position');

      function init() {
        // Setup modal listener
        handleOnPageImageClick();

        // Setup slick sliders on page load
        $('[data-category-id="0"]').addClass(activeGalleryControlClassname); // Categorized Gallery
        $('[data-key-feature-id="0"]').addClass(activeKeyFeatureControlsClassname); // Key Features

        // Set first Slide Group to be Active
        $('[data-gallery-id="0"]').addClass(galleryCategoryClassname).slick(gallerySlider); // Categorized Gallery
        // Key Features
        $('[data-key-features-gallery-id="0"]').parent().addClass(galleryCategoryClassname);
        $('[data-key-features-gallery-id="0"]')
			.slick(keyFeatureSlider)
			.on('breakpoint', function() {
				// Because the key features changes the number of slides it displays on breakpoint, it removes any
				// event on the slide. So we have to add the event back in
				$('[data-micromodal-trigger="modal-key-features"]').on('click', gotoProperModalSlider);
			});

        // Set first Slide Group in the Modal to be Active
        // Categorized Gallery
        updateModalPositionInfo('[data-modal-gallery-id="0"]');
        $('[data-modal-gallery-id="0"]').addClass(galleryCategoryClassname).slick(modalSlider);
        // Key Features
        updateModalPositionInfo('[data-modal-key-features-id="0"]');
        $('[data-modal-key-features-id="0"]').addClass(galleryCategoryClassname).slick(modalSlider);

        // Setup active Category for Mobile
        $('.categorize-gallery__mobile-active-control').html(getActiveCategoryText);
        $('.key-features__mobile-active-control').html(getActiveKeyFeatureText);

        initializeMicroModal();
      }

      function getActiveCategoryText() {
        return $('.categorized-gallery__control--active').html();
      }

      function getActiveKeyFeatureText() {
        return $('.key-features__control--active').html();
      }

      function updateModalPositionInfo(modalGalleryClassname) {
        let $slickElement = $(modalGalleryClassname);

        // set a baseline in case there's only one slide
        $slidePosition.text('1/1');

        $slickElement.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
          //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
          let i = (currentSlide ? currentSlide : 0) + 1;
          // use dots to get some count information
          $slidePosition.text(i + '/' + (slick.slideCount));
        });
      }

      function gotoProperModalSlider() {

        const slideToGoto = $(this).attr('data-slide-goto');
        const micromodalTrigger = $(this).attr('data-micromodal-trigger');
        let slickModelSelector = '';

        switch(micromodalTrigger) {
          case 'modal-key-features':
            slickModelSelector = '.slick-initialized[data-modal-key-features-id]';
            break;
          case 'modal-gallery':
            slickModelSelector = '.slick-initialized[data-modal-gallery-id]';
            break;
        }

        const slickModal = $(slickModelSelector);
        slickModal.slick('slickGoTo', slideToGoto, true);
        slickModal.slick('refresh'); // Correct any incorrect width calculations
      }

      function handleOnPageImageClick() {
        $('[data-micromodal-trigger="modal-gallery"]').on('click', gotoProperModalSlider);
        $('[data-micromodal-trigger="modal-key-features"]').on('click', gotoProperModalSlider);
      }

      function initializeMicroModal() {
        MicroModal.init({
          onClose: function (modal, element, event) {
            modal.querySelectorAll('.slide__video-wrapper > iframe').forEach(iframe => {
              iframe.setAttribute('src', iframe.getAttribute('src'));
            });
			event.preventDefault();
			event.stopPropagation();
          },
          disableScroll: true,
        });
      }

      init();

      // display the mobile controls
      $('.categorize-gallery__mobile-active-control').on('click', function() {
        $('.categorized-gallery__control-wrapper').toggleClass(mobileControlsClassname);
      });

      $('.key-features__mobile-active-control').on('click', function() {
        $('.key-features__control-wrapper').toggleClass(mobileKeyFeaturesControlsClassname);
      });

      // Categorized Gallery Controls
      $('.categorized-gallery__control').on('click', function() {
        // hide all other sliders of this type
        // Controls Unset
        $('[data-category-id]').removeClass(activeGalleryControlClassname);

        // On Page Slider Hide
        $('[data-gallery-id]').removeClass(galleryCategoryClassname);

        // On Page Slider Destroy
        $('.slick-initialized[data-gallery-id]').slick('unslick');

        // Modal Slider Hide
        $('[data-modal-gallery-id]').removeClass(galleryCategoryClassname);

        // Modal Slider Destroy
        $('.slick-initialized[data-modal-gallery-id]').slick('unslick');

        // Set Active Desktop Control
        $(this).addClass(activeGalleryControlClassname);

        // Set Active Mobile Control
        $('.categorize-gallery__mobile-active-control').html(getActiveCategoryText);
        $('.categorized-gallery__control-wrapper').removeClass(mobileControlsClassname);

        // Get the Category Id of this Category
        let $categoryId = $(this).attr('data-category-id');

        // Create the On Page Slider
        $('[data-gallery-id="'+$categoryId+'"]').addClass(galleryCategoryClassname).slick(gallerySlider);

        // Create the Modal Slider
        updateModalPositionInfo('[data-modal-gallery-id="'+$categoryId+'"]');
        $('[data-modal-gallery-id="'+$categoryId+'"]').addClass(galleryCategoryClassname).slick(modalSlider);

        // Setup modal listener. Unslick has removed it. Thanks unslick.
        handleOnPageImageClick();
      });

      // Key Features Controls
      $('.key-features__control').on('click', function () {
        // hide all other sliders of this type
        // Controls Unset
        $('[data-key-feature-id]').removeClass(activeKeyFeatureControlsClassname);

        // On Page Slider Hide
        $('[data-key-features-gallery-id]').parent().removeClass(galleryCategoryClassname);

        // On Page Slider Destroy
        $('.slick-initialized[data-key-features-gallery-id]').slick('unslick');

        // Modal Slider Hide
        $('[data-modal-key-features-id]').removeClass(galleryCategoryClassname);

        // Modal Slider Destroy
        $('.slick-initialized[data-modal-key-features-id]').slick('unslick');

        // Set Active Desktop Control
        $(this).addClass(activeKeyFeatureControlsClassname);

        // Set Active Mobile Control
        $('.key-features__mobile-active-control').html(getActiveKeyFeatureText);
        $('.key-features__control-wrapper').removeClass(mobileKeyFeaturesControlsClassname);

        // Get the Key Feature Id of this Feature
        const $keyFeaturesId = $(this).attr('data-key-feature-id');

        // Create the On Page Slider
        $('[data-key-features-gallery-id="'+$keyFeaturesId+'"]').parent().addClass(galleryCategoryClassname);
        $('[data-key-features-gallery-id="'+$keyFeaturesId+'"]').slick(keyFeatureSlider);

        // Create the Modal Slider
        updateModalPositionInfo('[data-modal-key-features-id="'+$keyFeaturesId+'"]');
        $('[data-modal-key-features-id="'+$keyFeaturesId+'"]').addClass(galleryCategoryClassname).slick(modalSlider);

        handleOnPageImageClick();

        initializeMicroModal(); // re-initalize micromodal for cloned slick sliders
      });
    });
  });

} ( this, jQuery ));

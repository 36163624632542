(function( root, $, undefined ) {
	"use strict";

	$(function () {

		$('.product-grid.compare-feature input[type=checkbox]').on('change', function(e) {

			// Make sure we can't click more than two.
			if ($('.product-grid.compare-feature input[type=checkbox]:checked').length > 2) {
				$(this).prop('checked', false);
			};

			if ($(this).prop('checked')) {
				$(this).parents('.single-header').addClass('selected');
			}
			else {
				$(this).parents('.single-header').removeClass('selected');
			}

			// Grab all models that are selected, push them into an array.
			var selected = [];
			$('.product-grid.compare-feature input[type=checkbox]:checked').each(function() {
			  selected.push($(this).attr('data-model'));
			});

			if (selected.length == 0) {
				$('.compare-bar').addClass('hide');
			}
			else if (selected.length == 1) {
				$('.compare-bar').removeClass('hide');
				$('.compare-bar .one-selected').css('display', 'inline-block');
				$('.compare-bar .two-selected').css('display', 'none');
			}
			else if (selected.length == 2) {
				$('.compare-bar').removeClass('hide');
				$('.compare-bar .one-selected').css('display', 'none');
				$('.compare-bar .two-selected').css('display', 'inline-block');
			}

			// Reset the compare bar.
			$('.compare-bar .bar .model-list').html('');

			// Update the compare bar with what's currently selected.
			for (var i = 0; i < selected.length; i++) {
				if (i == 0) {
					$('.compare-bar .bar .model-list').append('<span>' + selected[i] + '</span>');
				}
				else {
					$('.compare-bar .bar .model-list').append('<span> + ' + selected[i] + '</span>');
				}
			}

		});

		$('.two-selected button').on('click', function(e) {

			var selected = [];
			$('.product-grid.compare-feature input[type=checkbox]:checked').each(function() {
			  selected.push($(this).attr('value'));
			});

			$.each(selected, function() {
				$('.product-compare').find('#' + this).addClass('active'); 
			});

			$('html, body').animate({
        scrollTop: $(".prebody-content h1").offset().top
      }, 1000);

			$('.product-grid.compare-feature').addClass('hide');
			$('.product-compare').addClass('active');

			$('.two-selected').css('display', 'none');
			$('.compare-clear').css('display', 'inline-block');

		});

		$('.compare-clear button').on('click', function(e) {
			$('.product-compare').removeClass('active');
			$('.product-grid.compare-feature').removeClass('hide');
			$('.product-compare .grid-single.active').removeClass('active');

			$('.product-grid.compare-feature input[type=checkbox]:checked').each(function() {
			  $(this).prop('checked', false);
				$(this).parents('.single-header').removeClass('selected');
			});

			$('.compare-clear').css('display', 'none');
			$('.compare-bar .bar .model-list').html('');
			$('.compare-bar').addClass('hide');
		});

	});

} ( this, jQuery ));

(function( _, $ ) {
	"use strict";
	
	$(function () {

        // Archive nav open and close
			$(".blog-archive-nav--menu").click(function() {
				$(".blog-archive-nav--toggle").slideToggle('fast', function() {
					$(this).attr('style', '').toggleClass('nav-open');
				});
			});

			// Add class to archive nav on scroll
			// This is used for docking ontop of the normal nav
			$(window).scroll(function(){
				var ScrollTop = parseInt($(window).scrollTop());
                var headerOffSet = $('.blog-archive--header').height();

				if (ScrollTop > headerOffSet) {
					$('.blog-archive-nav').addClass('has-top');
				} else {
					$('.blog-archive-nav').removeClass('has-top');
				}
			});

			var lastBlogScrollTop = 0;
			window.addEventListener("scroll", function(){
               var stBlog = window.pageYOffset || document.documentElement.scrollTop;
               var topSize = $('.header').height();

			   if (stBlog > lastBlogScrollTop){
				  $('.has-top').css("top", "0");
			   } else {
				  $('.has-top').css("top", topSize);
			   }
			   lastBlogScrollTop = stBlog <= 0 ? 0 : stBlog;
            }, false);

    });

} ( this, jQuery ));
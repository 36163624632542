(function( _, $ ) {
	"use strict";
	
	$(function () {

			var copyTextareaBtn = document.querySelectorAll('.social-share--website');

			for (var i= 0; i < copyTextareaBtn.length; i++) {
				var self = copyTextareaBtn[i];

				self.addEventListener('click', function(event) {
					var copyTextarea = document.querySelector('.social-share--website-text');
					copyTextarea.focus();
					copyTextarea.select();
	
					try {
						var successful = document.execCommand('copy');
						var msg = successful ? 'successful' : 'unsuccessful';
						alert('Copying link to clipboard was ' + msg);
					} catch (err) {
						alert('Oops, unable to copy');
					}
				});

			}
            
    });

} ( this, jQuery ));
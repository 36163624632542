import currency from 'currency.js';

(function( _, $ ) {
	"use strict";
	$(function () {
		function setupStepTwo(selectedModel) {
			$('.js-input__image').attr('src', models[selectedModel].image);
			$('.js-input__name').html(models[selectedModel].name);
			$('.js-input__price-usd').html('<span>' + models[selectedModel].price.usd + '</span> USD');
			$('.js-input__price-cad').html('<span>' + models[selectedModel].price.cad + '</span> CAD');
			if (models[selectedModel].fuel == 'Diesel') {
				$('.js-input__fuel').attr('data', '/wp-content/themes/zf-royalty/src/img/PW-DieselPump.svg');
			}
			else {
				$('.js-input__fuel').attr('data', '/wp-content/themes/zf-royalty/src/img/PW-GasPump.svg');
			}
			$('.js-input__brand').attr('data', models[selectedModel].brand);
			$('.js-input__length').html(models[selectedModel].length);
			// Update the state of the bar now that it has the correct information.
			$('.build-bar .start-state').css('display', 'none');
			$('.build-bar .model').css('display', 'flex');
			// Show the button to move to the next step.
			$('.build-bar .next-button').css('display', 'block');
			$('.next-button .step-name').html('Exterior');
		}
		var globalOptionList = [];
		let sp = new URLSearchParams(location.search);
		function initBuildPrice() {
			$('.build-slider').slick({
				accessibility: false,
				adaptiveHeight: true,
				arrows: false,
				dots: true,
				draggable: false,
				touchMove: false,
				swipe: false,
				responsive: [{
		      breakpoint: 670,
		      settings: {
		        dots: false
		      }
		    }]
			});

			$('.build-slider').on('afterChange', function() {
				$('html, body').animate({
					scrollTop: $("main > section > .go-back").offset().top - 120
				}, 1000);
			});

			setupCheckboxListeners();

			if (sp.has('selectedModel') && sp.has('selectedModelClass')) {
				let selectedModelClassName = sp.get('selectedModelClass');
				let selectedModel = sp.get('selectedModel');
				setupStepTwo(selectedModelClassName);
				finishStepOne(selectedModelClassName, selectedModel);
			} else {
				initStepOne();
			}
		}

		function setupCheckboxListeners() {
			$('.product-grid.bp input[type=checkbox]').on('change', function(e) {
				// Make sure we can't click more than one.
				if ($('.product-grid.build-price-feature input[type=checkbox]:checked').length > 1) {
					$('.product-grid.build-price-feature input[type=checkbox]:checked').parents('.single-header').removeClass('selected');
					$('.product-grid.build-price-feature input[type=checkbox]:checked').prop('checked', false);
					$(this).prop('checked', true);
				};
				if ($('.product-grid.build-price--exterior input[type=checkbox]:checked').length > 1) {
					$('.product-grid.build-price--exterior input[type=checkbox]:checked').parents('.single-header').removeClass('selected');
					$('.product-grid.build-price--exterior input[type=checkbox]:checked').prop('checked', false);
					$(this).prop('checked', true);
				};
				if ($('.product-grid.build-price--cabinetry input[type=checkbox]:checked').length > 1) {
					$('.product-grid.build-price--cabinetry input[type=checkbox]:checked').parents('.single-header').removeClass('selected');
					$('.product-grid.build-price--cabinetry input[type=checkbox]:checked').prop('checked', false);
					$(this).prop('checked', true);
				};
				if ($('.product-grid.build-price--countertops input[type=checkbox]:checked').length > 1) {
					$('.product-grid.build-price--countertops input[type=checkbox]:checked').parents('.single-header').removeClass('selected');
					$('.product-grid.build-price--countertops input[type=checkbox]:checked').prop('checked', false);
					$(this).prop('checked', true);
				};
				if ($('.product-grid.build-price--upholstery input[type=checkbox]:checked').length > 1) {
					$('.product-grid.build-price--upholstery input[type=checkbox]:checked').parents('.single-header').removeClass('selected');
					$('.product-grid.build-price--upholstery input[type=checkbox]:checked').prop('checked', false);
					$(this).prop('checked', true);
				};
				if ($(this).prop('checked')) {
					$(this).parents('.single-header').addClass('selected');
				}
				else {
					$(this).parents('.single-header').removeClass('selected');
				}
			});
		}

		function finishStepOne(selectedModel, selectedModelClassName) {
			// Hide the button in the bar, as we now have a new slide to complete.
			$('.build-bar .next-button').css('display', 'none');

			$('.build-slider').slick('slickNext');
			$('.slick-dots li:nth-child(1) button').html('✔︎');

			initStepTwo(selectedModel, selectedModelClassName);
		}

		function initStepOne() {
			let selectedModelClassName;
			let selectedModel;
			$('.bp--step-one input[type=checkbox]').on('change', function() {
				if ($(this).prop('checked') ) {
					selectedModel = $(this).attr('data-model');
					selectedModelClassName = $(this).attr('value');

					// Populate the build bar along the bottom with the currently selected model's information.
					setupStepTwo(selectedModel);
				}
				else if ($('.bp--step-one input[type=checkbox]:checked').length < 1){
					// If there is no currently selected model, then update the bar accordingly.
					$('.build-bar .model').css('display', 'none');
					$('.build-bar .next-button').css('display', 'none');
					$('.build-bar .start-state').css('display', 'block');
				}
			});

			// If the next-button is clicked, then we need to transition to the next slide, and mark this slide complete.
			$('.next-button button').on('click', function(e) {
				finishStepOne(selectedModel, selectedModelClassName);
			});
		}

		// Exterior
		function initStepTwo(currentModel, currentModelClassName) {
			// Update the tag line for the current slide.
			$('.go-back').css('display', 'block');
			$('.prebody-content > p').html('Choose an exterior colour.');

			// Make sure we're showing the correct content for the selected model.
			$('.product-grid.bp.build-price--exterior > div').css('display', 'none');
			$('.product-grid.bp.build-price--exterior > div.' + currentModelClassName).css('display', 'flex');

			// If an option is selected...
			$('.bp--step-two input[type=checkbox]').on('change', function(e) {
				var selectedExterior;
				var $this;

				if ($(this).prop('checked') ) {
					// Grab the option that is selected.
					selectedExterior = $(this).parents('.grid-single');
					$this = $(this)

					// Show the button to move to the next slide.
					$('.next-button .step-name').html('Cabinetry');
					$('.build-bar .next-button').css('display', 'block');
				};

				$('.next-button button').off();
				$('.next-button button').on('click', function(e) {
					// Hide the button in the bar, as we now have a new slide to complete.
					$('.build-bar .next-button').css('display', 'none');

					// Put the selected option into a container to save for display on the summary slide.
					globalOptionList.push(selectedExterior);

					// Check to see if the option selected has a price attached to it.
					// If there's a price attached, then update the total price in the build bar.
					if ( $this.parents('.grid-single').find('.cur__US, .cur__CAD')[0] ) {
						updatePrice($this);
					}

					// Make sure we're showing the correct content for the selected model.
					$('.product-grid.bp.build-price--cabinetry > div').css('display', 'none');
					$('.product-grid.bp.build-price--cabinetry > div.' + currentModelClassName).css('display', 'flex');

					$('.build-slider').slick('slickNext');
					$('.slick-dots li:nth-child(2) button').html('✔︎');

					initStepThree(currentModel, currentModelClassName);
				});

			});
		}

		// Cabinetry
		function initStepThree(currentModel, currentModelClassName) {

			// Update the tag line for the current slide.
			$('.prebody-content > p').html('Choose a style and colour of cabinetry.');

			// If an option is selected...
			$('.bp--step-three input[type=checkbox]').on('change', function(e) {
				var selectedCabinetry;
				var $this;

				if ($(this).prop('checked') ) {
					// Grab the option that is selected.
					selectedCabinetry = $(this).parents('.grid-single');
					$this = $(this);

					// Show the button to move to the next slide.
					$('.next-button .step-name').html('Countertops');
					$('.build-bar .next-button').css('display', 'block');
				};

				$('.next-button button').off();
				$('.next-button button').on('click', function(e) {
					// Hide the button in the bar, as we now have a new slide to complete.
					$('.build-bar .next-button').css('display', 'none');

					globalOptionList.push(selectedCabinetry);

					// Check to see if the option selected has a price attached to it.
					// If there's a price attached, then update the total price in the build bar.
					if ( $this.parents('.grid-single').find('.cur__US, .cur__CAD')[0] ) {
						updatePrice($this);
					}

					// Make sure we're showing the correct content for the selected model.
					$('.product-grid.bp.build-price--countertops > div').css('display', 'none');
					$('.product-grid.bp.build-price--countertops > div.' + currentModelClassName).css('display', 'flex');

					$('.build-slider').slick('slickNext');
					$('.slick-dots li:nth-child(3) button').html('✔︎');
					initStepFour(currentModel, currentModelClassName);
				});

			});
		}

		// Countertops
		function initStepFour(currentModel, currentModelClassName) {

			// Update the tag line for the current slide.
			$('.prebody-content > p').html('Choose a Corian® countertop material colour.');

			// If an option is selected...
			$('.bp--step-four input[type=checkbox]').on('change', function(e) {
				var selectedCountertops;
				var $this;

				if ($(this).prop('checked') ) {
					// Grab the option that is selected.
					selectedCountertops = $(this).parents('.grid-single');
					$this = $(this);

					// Show the button to move to the next slide.
					$('.next-button .step-name').html('Upholstery');
					$('.build-bar .next-button').css('display', 'block');
				};

				$('.next-button button').off();
				$('.next-button button').on('click', function(e) {
					// Hide the button in the bar, as we now have a new slide to complete.
					$('.build-bar .next-button').css('display', 'none');

					globalOptionList.push(selectedCountertops);

					// Check to see if the option selected has a price attached to it.
					// If there's a price attached, then update the total price in the build bar.
					if ( $this.parents('.grid-single').find('.cur__US, .cur__CAD')[0] ) {
						updatePrice($this);
					}

					// Make sure we're showing the correct content for the selected model.
					$('.product-grid.bp.build-price--upholstery > div').css('display', 'none');
					$('.product-grid.bp.build-price--upholstery > div.' + currentModelClassName).css('display', 'flex');

					$('.build-slider').slick('slickNext');
					$('.slick-dots li:nth-child(4) button').html('✔︎');
					initStepFive(currentModel, currentModelClassName);
				});

			});
		}

		// Upholstery
		function initStepFive(currentModel, currentModelClassName) {
			// Update the tag line for the current slide.
			$('.prebody-content > p').html('Choose an Ultraleather® upholstery colour.');

			// If an option is selected...
			$('.bp--step-five input[type=checkbox]').on('change', function(e) {
				var selectedUpholstery;
				var $this;

				if ($(this).prop('checked') ) {
					// Grab the option that is selected.
					selectedUpholstery = $(this).parents('.grid-single');
					$this = $(this);

					// Show the button to move to the next slide.
					$('.next-button .step-name').html('Options');
					$('.build-bar .next-button').css('display', 'block');
				};

				$('.next-button button').off();
				$('.next-button button').on('click', function(e) {
					// Hide the button in the bar, as we now have a new slide to complete.
					$('.build-bar .next-button').css('display', 'none');

					globalOptionList.push(selectedUpholstery);

					// Check to see if the option selected has a price attached to it.
					// If there's a price attached, then update the total price in the build bar.
					if ( $this.parents('.grid-single').find('.cur__US, .cur__CAD')[0] ) {
						updatePrice($this);
					}

					// Make sure we're showing the correct content for the selected model.
					$('.product-grid.bp.build-price--options > div').css('display', 'none');
					$('.product-grid.bp.build-price--options > div.' + currentModelClassName).css('display', 'flex');

					$('.build-slider').slick('slickNext');
					$('.slick-dots li:nth-child(5) button').html('✔︎');

					initStepSix(currentModel, currentModelClassName);
				});
			});
		}

		// Options
		function initStepSix(currentModel, currentModelClassName) {

			// Update the tag line for the current slide.
			$('.prebody-content > p').html('Choose from a selection of add-ons and upgrades. Please only select one solar package.');

			var selectedOptions = [];
			var collection = [];

			$('.next-button .step-name').html('Summary');
			$('.build-bar .next-button').css('display', 'block');
			$('.next-button button').on('click', () => optionalStepClick(currentModel, currentModelClassName, selectedOptions, collection));

			$('.bp--step-six input[type=checkbox]').on('change', function(e) {

				if ($(this).prop('checked') ) {
					// Grab the option that is selected.
					selectedOptions.push( $(this).parents('.grid-single') );
					collection.push( $(this) );
				};

				$('.next-button button').off();
				$('.next-button button').on('click', () => optionalStepClick(currentModel, currentModelClassName, selectedOptions, collection));
			});
		}

		function optionalStepClick(currentModel, currentModelClassName, selectedOptions, collection) {
			// Hide the button in the bar, as we now have a new slide to complete.
			$('.build-bar .next-button').css('display', 'none');

			globalOptionList.push(selectedOptions);

			// Check to see if the option selected has a price attached to it.
			// If there's a price attached, then update the total price in the build bar.
			for (var item in collection) {
				if ( collection[item].parents('.grid-single').find('.cur__US, .cur__CAD')[0] ) {
					updatePrice(collection[item]);
				}
			}

			initSummary(currentModel, currentModelClassName);

			$('.build-slider').slick('slickNext');
			$('.slick-dots li:nth-child(6) button').html('✔︎');

			initStepSeven(currentModel, currentModelClassName);
		}

		// Summary
		function initStepSeven(currentModel, currentModelClassName) {
			$('.build-bar .download-button').css('display', 'block');

			// Update the tag line for the current slide.
			$('.prebody-content > p').html('Your specification and price summary.');

			$('.builder-container').css('display', 'none');
			$('.build-slider').slick('unslick');

			$('.bp--step-one').css('display', 'none');
			$('.bp--step-two').css('display', 'none');
			$('.bp--step-three').css('display', 'none');
			$('.bp--step-four').css('display', 'none');
			$('.bp--step-five').css('display', 'none');
			$('.bp--step-six').css('display', 'none');
			$('.bp--step-seven').css('display', 'block');

			$('.builder-container').css('display', 'block');

			$('.details--standard-features .accordion-toggle').on('click', function(e) {
				$(this).toggleClass('active');
				// Expand or collapse this panel
				$(this).next().slideToggle('slow');

				// Hide the other panels
				$(this).parents('.accordion').find($(".accordion-content").not($(this).next())).slideUp('fast');
				$(this).parents('.accordion').find($(".accordion-toggle").not($(this))).removeClass('active');
			});
		}

		function updatePrice(currObject) {
			var optionPriceUSD = currency(currObject.parents('.grid-single').find('.cur__US').text());
			var optionPriceCAD = currency(currObject.parents('.grid-single').find('.cur__CAD').text());

			var currentPriceUSD = currency($('.build-bar .js-input__price-usd > span').text());
			var currentPriceCAD = currency($('.build-bar .js-input__price-cad > span').text());

			var newPriceUSD = currency(0, { formatWithSymbol: true, precision: 0 }).add(currentPriceUSD).add(optionPriceUSD).format();
			var newPriceCAD = currency(0, { formatWithSymbol: true, precision: 0 }).add(currentPriceCAD).add(optionPriceCAD).format();

			$('.build-bar .js-input__price-usd > span').html(newPriceUSD);
			$('.build-bar .js-input__price-cad > span').html(newPriceCAD);
		}

		function initSummary(currentModel, currentModelClassName) {
			$('.build-price--final .final__header h2').html(models[currentModel].name);
			$('.build-price--final .final__header .cur__US').html('<span>MSRP</span><span>' + models[currentModel].price.usd + '</span> USD');
			$('.build-price--final .final__header .cur__CAD').html('<span>MSRP</span><span>' + models[currentModel].price.cad + '</span> CAD');
			$('.build-price--final .final__image img').attr('src', models[currentModel].image);

			$('.final__table .js__model-name').html(models[currentModel].name);
			$('.final__table .js__model-price .cur__US span').html(models[currentModel].price.usd);
			$('.final__table .js__model-price .cur__CAD span').html(models[currentModel].price.cad);

			var colourChoiceObject = $(globalOptionList[0]);
			var exteriorColourChoice = colourChoiceObject.find(".single-header .header-left span").text();
			$('.final__table .js__colour-picked').html(exteriorColourChoice);

			if ( colourChoiceObject.find('.cur__US, .cur__CAD')[0] ) {
				var exteriorColourChoicePrice = {
					usd: colourChoiceObject.find(".cur__US").text(),
					cad: colourChoiceObject.find(".cur__CAD").text(),
				};
				$('.final__table .js__colour-picked--price .cur__US span').html(exteriorColourChoicePrice.usd);
				$('.final__table .js__colour-picked--price .cur__CAD span').html(exteriorColourChoicePrice.cad);
			}
			else {
				$('.final__table .js__colour-picked--price .cur__US, .final__table .js__colour-picked--price .cur__CAD').html('&mdash;');
			}

			var cabinetryChoiceObject = $(globalOptionList[1]);
			var cabinetryChoice = cabinetryChoiceObject.find(".single-header .header-left span").text();
			$('.final__table .js__cabinetry-picked').html(cabinetryChoice);

			if ( cabinetryChoiceObject.find('.cur__US, .cur__CAD')[0] ) {
				var cabinetryChoicePrice = {
					usd: cabinetryChoiceObject.find(".cur__US").text(),
					cad: cabinetryChoiceObject.find(".cur__CAD").text(),
				};
				$('.final__table .js__cabinetry-picked--price .cur__US').html(cabinetryChoicePrice.usd);
				$('.final__table .js__cabinetry-picked--price .cur__CAD').html(cabinetryChoicePrice.cad);
			}
			else {
				$('.final__table .js__cabinetry-picked--price .cur__US, .final__table .js__cabinetry-picked--price .cur__CAD').html('&mdash;');
			}

			var countertopsChoiceObject = $(globalOptionList[2]);
			var countertopsChoice = countertopsChoiceObject.find(".single-header .header-left span").text();
			$('.final__table .js__countertops-picked').html(countertopsChoice);

			if ( countertopsChoiceObject.find('.cur__US, .cur__CAD')[0] ) {
				var countertopsChoicePrice = {
					usd: countertopsChoiceObject.find(".cur__US").text(),
					cad: countertopsChoiceObject.find(".cur__CAD").text(),
				};
				$('.final__table .js__countertops-picked--price .cur__US').html(countertopsChoicePrice.usd);
				$('.final__table .js__countertops-picked--price .cur__CAD').html(countertopsChoicePrice.cad);
			}
			else {
				$('.final__table .js__countertops-picked--price .cur__US, .final__table .js__countertops-picked--price .cur__CAD').html('&mdash;');
			}

			var upholsteryChoiceObject = $(globalOptionList[3]);
			var upholsteryChoice = upholsteryChoiceObject.find(".single-header .header-left span").text();
			$('.final__table .js__upholstery-picked').html(upholsteryChoice);

			if ( upholsteryChoiceObject.find('.cur__US, .cur__CAD')[0] ) {
				var upholsteryChoicePrice = {
					usd: upholsteryChoiceObject.find(".cur__US").text(),
					cad: upholsteryChoiceObject.find(".cur__CAD").text(),
				};
				$('.final__table .js__upholstery-picked--price .cur__US').html(upholsteryChoicePrice.usd);
				$('.final__table .js__upholstery-picked--price .cur__CAD').html(upholsteryChoicePrice.cad);
			}
			else {
				$('.final__table .js__upholstery-picked--price .cur__US, .final__table .js__upholstery-picked--price .cur__CAD').html('&mdash;');
			}

			var optionsArray = $(globalOptionList[4]);

			optionsArray.each(function(index) {
				var currentOptionBrand = $(this).find(".single-header .type").text();
				var currentOptionName = $(this).find(".single-header .header-left span").text();
				var optionString;
				var currentOptionPrice;

				if (index == 0) {
					optionString = 'Options';
				}
				else {
					optionString = '&nbsp;';
				}

				if ( $(this).find('.cur__US, .cur__CAD')[0] ) {
					currentOptionPrice = {
						usd: $(this).find(".cur__US").text(),
						cad: $(this).find(".cur__CAD").text(),
					};

					$('.final__table table tbody').append('<tr><td>' + optionString + '</td><td>' + currentOptionBrand + ' ' + currentOptionName + '</td><td><span class="cur__US">' + currentOptionPrice.usd + '</span><span class="cur__CAD">' + currentOptionPrice.cad + '</span></td></tr>');
				}
				else {
					currentOptionPrice = '&mdash;';

					$('.final__table table tbody').append('<tr><td>' + optionString + '</td><td>' + currentOptionBrand + ' ' + currentOptionName + '</td><td>' + currentOptionPrice + '</td></tr>');
				}
			});

			var finalPriceUSD = $('.build-bar .js-input__price-usd > span').text();
			var finalPriceCAD = $('.build-bar .js-input__price-cad > span').text();
			$('.final__table .js__final-total .cur__US').html(finalPriceUSD + ' USD');
			$('.final__table .js__final-total .cur__CAD').html(finalPriceCAD + ' CAD');


			for (var item in globalOptionList) {
				if (item == 4) {
					$(globalOptionList[item]).each(function(index) {
						var currentClone = $(this).clone();
						$('.final__grid .product-grid').append(currentClone);
					});
				}
				else {
					var currentClone = $(globalOptionList[item]).clone();
					$('.final__grid .product-grid').append(currentClone);
				}
			}

			$('.final__specs > div').css('display', 'none');
			$('.final__specs > div.' + currentModelClassName).css('display', 'block');
		}

		initBuildPrice();


	});

} ( this, jQuery ));

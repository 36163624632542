import slick from 'slick-carousel';

(function( root, $, undefined ) {
  "use strict";

  $(function () {

    $(document).ready(function($) {

      $('.homepage__banner .slider').slick({
        adaptiveHeight: true,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 6000,
        dots: true,
        prevArrow: $('.homepage__banner .prev-arrow'),
        nextArrow: $('.homepage__banner .next-arrow')
      });

      $('.tab-slider').each(function(index) {
        $(this).slick({
          adaptiveHeight: true,
          arrows: false,
          dots: true,
          customPaging : function(slider, i) {
            var text = $(slider.$slides[i]).find('.single').data('select');
            return '<a>' + text + '</a>';
          },
          responsive: [{
            breakpoint: 1000,
            settings: {
              customPaging : function(slider, i) {
                return '<button type="button" role="tab">' + index + '</button>';
              },
            }
          }]
        });
      });

      $('.three-sixty__slider .slider').slick({
        dots: true,
        fade: true,
        prevArrow: $('.model__three-sixty .prev-arrow'),
        nextArrow: $('.model__three-sixty .next-arrow'),
      });

      $('.floor-plans__slider').slick({
        adaptiveHeight: true,
        fade: true,
        prevArrow: $('.model__floor-plans .prev-arrow'),
        nextArrow: $('.model__floor-plans .next-arrow'),
      });

      $('.gallery__slider').slick({
        adaptiveHeight: true,
        centerMode: true,
        centerPadding: '200px',
        slidesToShow: 1,
        prevArrow: $('.model__gallery .prev-arrow'),
        nextArrow: $('.model__gallery .next-arrow'),
        variableWidth: true,
        responsive: [{
          breakpoint: 1100,
          settings: {
            centerPadding: '100px',
          }
        }, {
          breakpoint: 800,
          settings: {
            centerPadding: '25px',
          }
        }]
      });

      $('body').addClass('js');
      var $menu = $('#menu'),
        $menulink = $('.menu-link'),
        $menuTrigger = $('.menu-item-has-children > a');

      $menulink.click(function(e) {
        e.preventDefault();
        $menulink.toggleClass('active');
        $menu.toggleClass('active');
      });

      $menuTrigger.click(function(e) {
        e.preventDefault();
        var $this = $(this);
        $this.toggleClass('active').next('ul').toggleClass('active');
      });

      stickybits('header.header', { useStickyClasses: true });

      var lastScrollTop = 0;
      window.addEventListener("scroll", function(){
        var st = window.pageYOffset || document.documentElement.scrollTop;
        if (st > lastScrollTop){
          $('header').css('top', '-120px');
        } else {
          $('header').css('top', '0px');
        }
        lastScrollTop = st <= 0 ? 0 : st;
      }, false);


    });
  });

} ( this, jQuery ));
